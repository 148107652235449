import { observable } from 'mobx'
import { Model, Store } from 'store/Base';
import { OutShipmentStore } from './OutShipment';


export class PackStationBox extends Model {
  static backendResourceName = 'pack_station_box'
  static idPrefix = 'PB'
  static idColor = 'green'
  static idIcon = 'box'

  @observable id = null
  @observable serialNumber = ''
  @observable deleted = false

  relations() {
    return {
      outShipments: OutShipmentStore,
    }
  }
}

export class PackStationBoxStore extends Store {
  static backendResourceName = 'pack_station_box'
  Model = PackStationBox
}
