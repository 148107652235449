import { observable } from 'mobx'
import { Model, Store } from 'store/Base';
import { OutShipmentStore } from './OutShipment';


export class PackStation extends Model {
  static backendResourceName = 'pack_station'
  static idPrefix = 'PS'
  static idColor = 'black'
  static idIcon = 'boxes'

  @observable id = null
  @observable name = ''
  @observable serialNumber = ''
  @observable size = 1
  @observable assignedOrdersCount = true
  @observable deleted = false

  relations() {
    return {
      outShipments: OutShipmentStore,
    }
  }
}

export class PackStationStore extends Store {
  static backendResourceName = 'pack_station'
  Model = PackStation
}
